<template>
    <div class="box">
        <NlTable :columns="columns" :get-data="getData">
            <template #url_submitted_at="{ props: { row } }">
                {{ moment(row.url_submitted_at).format('DD/MM/YYYY') }}
            </template>

            <template #nl_url="{ props: { row } }">
                <TrueFalseBadge :value="row.nl_url !== null && row.nl_url.saleable" />
            </template>

            <template #user="{ props: { row } }">
                {{ row.user.email }}
            </template>

            <template #userSearch="{ props }">
                <b-input
                    v-model="props.filters['user_email']"
                    :controls="false"
                    size="is-small"
                    placeholder="Soumis par"
                />
            </template>

            <template #keyword="{ props: { row } }">
                <a target="_blank" :href="'/search/keyword?keyword=' + encodeURI(row.preorder.keyword)">{{
                    row.preorder.keyword
                }}</a>
            </template>

            <template #keywordSearch="{ props }">
                <b-input v-model="props.filters['keyword']" :controls="false" size="is-small" placeholder="Mot-clé" />
            </template>

            <template #nl_urlSearch="{ props }">
                <b-select v-model="props.filters['indexed']" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option :value="1">Oui</option>
                    <option :value="0">Non</option>
                </b-select>
            </template>
        </NlTable>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import TrueFalseBadge from '@/components/UI/TrueFalseBadge.vue'

    export default {
        name: 'AdminListing',
        components: { TrueFalseBadge, NlTable },
        title: 'Historique des liens proposés',
        data: () => ({
            columns: [
                {
                    field: 'keyword',
                    label: 'Mot-clé',
                    custom: true,
                    searchable: true,
                    customSearch: true
                },
                {
                    field: 'nl_url',
                    label: 'Positionné',
                    customSearch: true,
                    searchable: true,
                    center: true,
                    custom: true
                },
                {
                    field: 'url',
                    searchable: true,
                    label: 'URL'
                },
                {
                    field: 'user',
                    label: 'Soumis par',
                    custom: true,
                    searchable: true,
                    customSearch: true
                },
                {
                    field: 'url_submitted_at',
                    label: 'Soumis le',
                    custom: true,
                    sortable: true
                }
            ]
        }),
        methods: {
            ...mapActions('urls', {
                getData: 'getUrlsHistory'
            })
        }
    }
</script>
